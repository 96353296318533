<template>
  <!-- User sign in section -->
  <div v-if="loading">
      <div class="spinner-grow spinner-grow-sm green" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow spinner-grow-sm green" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow spinner-grow-sm green" role="status">
        <span class="sr-only">Loading...</span>
      </div>
  </div>
  <div v-else>
    <div class="userContainer container-fluid text-center" v-if="!loged">
      <h1 class="sign-in">Sign in</h1>
      <div class="form-group">
        <label class="font-weight-bold" for="user"  maxlength="20">Username:</label>
        <input type="text" class="form-control" v-model="localUser.user" placeholder="user...">
      </div>
      <div class="form-group">
        <label class="font-weight-bold" for="pass"  maxlength="20">Password:</label>
        <input type="password" class="form-control" v-model="localUser.pass" placeholder="******">
      </div>
      <button 
        class="btn btn-success form-control" 
        :disabled="localUser.user.length == 0 || localUser.pass.length == 0"
        @click="signIn">
        Sign in
      </button>
      <router-link to="/signup">
        <button class="btn btn-outline-success form-control">Sign up</button>
      </router-link>
      <div class="footer text-center">
        <router-link to="/about">
          <button class="btn btn-sm btn-outline-success form-control">About <i class="bi bi-info-circle-fill"></i></button>
        </router-link>
      </div>
    </div>
    <!-- User information section -->
    <div class="userContainer container-fluid text-center" v-else>
      <h1 class="wellcome">Wellcome!</h1>
      <h3 class="user">{{ localUser.user }}</h3>
      <p>Now you can store your notes in the cloud!</p>
      <button 
        class="btn btn-danger form-control btnLogOut" 
        @click="logOut">
        Log out
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'User',
   data() {
    return {
      localUser: {
        user: '',
        pass: ''
      },
      loged: false,
      token: null,
      errored: false,
      loading: true
    }
  },
  methods: {
    // Sign in 
    async signIn() {
      var datos = new FormData();
      datos.append("user", this.localUser.user);
      datos.append("pass", this.localUser.pass);
      var url = 'https://cronos8studio.com/smallnotesBack/php/signIn.php';
      // send data
      try {
        let response = await fetch(url, {
          method: 'POST',
          body: datos
        })
        // response
        if (response) {
            let jsonResponse = await response.json();
            console.log(jsonResponse);

            // validate
            if (jsonResponse.ok) {
              this.token = jsonResponse.value;
              this.saveToken();
              this.checkToken();
            }
        }
      } catch(error) {
        console.log(error);
      }

      this.clearForm();
    },
    // Save token
    saveToken() {
      localStorage.setItem("token", this.token);
    },
    // Check token
    async checkToken() {
      var datos = new FormData();
      datos.append("token", this.token);
      var url = 'https://cronos8studio.com/smallnotesBack/php/checkToken.php';
      // send data
      try {
        let response = await fetch(url, {
          method: 'POST',
          body: datos
        })
        // response
        if (response) {
            let jsonResponse = await response.json();
            console.log(jsonResponse);

            // get token data
            if (jsonResponse.ok) {
              this.loged = true;
              this.localUser.user = jsonResponse.value.user;
            } else {
              this.token = null;
              this.loged = false;
              localStorage.removeItem("token");
            }
        }
      } catch(error) {
        console.log(error);
      }
    },
    // Log out
    logOut() {
      this.loged = false;
      localStorage.removeItem("token");
      this.clearForm();
    },
    // Clear
    clearForm() {
      this.localUser.user = '';
      this.localUser.pass = '';
    }
  },
  // Execute when mounted
  mounted() {
    this.$nextTick(function () {
      // Check Token
      if (localStorage.getItem("token") != undefined) {
        this.token = localStorage.getItem("token");
        this.checkToken();
      } else {
        this.logOut();
      }

      setTimeout(() => {
        this.loading = false
      }, 1500)
    })
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.green {
  color: #42b883;
}
.spinner-grow {
  margin: 5px;
}
.userContainer {
  margin-top: 14vh;
  height: auto;
  width: 100%;
  padding-right: 2vh;
  padding-left: 2vh;
}
.sign-in {
  font-size: 5vh;
  padding: 1vh;
}
.wellcome {
  font-size: 5vh;
  padding: 1vh;
}
.user {
  font-size: 2vh;
  padding: 1vh;
}
.form-group label {
  font-size: 2vh;
  padding: 1vh;
}
.form-group input {
  height: 5vh;
  padding: 1vh;
  font-size: 2vh;
}
.btn {
  height: 6vh;
  margin-top: 2vh;
  font-size: 2vh;
}
.btnAbout {
  width: 10vh;
}
.footer {
  position: relative;
  margin-top: 24vh;
}
/* loged in user section */
.p {
  font-size: 2vh;
  padding: 1vh;
}
.btnLogOut {
  margin-top: 50vh;
}
</style>